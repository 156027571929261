import DOMPurify from 'isomorphic-dompurify';

import { MPDivider, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTContractQuery } from 'graphql/__generated__/NFTContractQuery.graphql';
import { EthereumContractTypeEnum } from 'types/__generated__/graphql';

import DefaultSuspense from 'components/DefaultSuspense';
import useProductOwnership from 'hooks/product/useProductOwnership';
import useProductState from 'hooks/product/useProductState';
import useProductTimers from 'hooks/product/useProductTimers';
import useSession from 'hooks/useSession';
import { NFTType } from 'types/graphql/NFT';
import generateTitleText from 'utils/generateTitleText';
import { nftHasRankedAuction } from 'utils/nftUtils';

import ProductOwnerMode from './owner/ProductOwnerMode';
import ProductAboutArtists from './aboutArtists';
import ClaimUnlockable from './claimUnlockable';
import ProductActions from './productActions';
import ProductAttributes from './productAttributes';
import ProductAuthorDetails from './ProductAuthorDetails';
import { ProductDescriptions } from './ProductDescription';
import ProductDetailsMetadata from './ProductDetailsMetadata';
import ProductExhibition from './ProductExhibition';
import ProductLabels from './productLabels';
import ProductProvenance from './productProvenance';
import ProductSocialBar from './productSocialBar';
import ProductTags from './ProductTags';
import ProductTitle from './ProductTitle';
import RankedAuction from './rankedAuction';

import * as styles from 'css/pages/product/ProductLabels.module.css';

interface ProductRightRailProps {
  approvalRegistryContract: NFTContractQuery['response']['nftContract'];
  invalidate: () => void;
  nft: NFTType;
}

export default function ProductRightRail({
  nft,
  approvalRegistryContract,
  invalidate,
}: ProductRightRailProps) {
  const nftId = parseInt(nft.pk, 10);

  const session = useSession();

  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const isCreatorOfNFT = nft.metadata.author.pk === nft.currentOwner.pk;

  const {
    showRankedAuctionTimer,
    showProductAuctionTimer,
    showDropsTimer,
    onAuctionUpdate,
    onProductTimerEnd,
  } = useProductTimers(nft);
  const { isPendingSale } = useProductState({
    nft,
    showDropsTimer,
    showProductAuctionTimer,
    showRankedAuctionTimer,
  });

  const hasAuction = !!nft.listing.rankedAuction;
  const endedAuctionWithoutLiveBid =
    hasAuction &&
    nft.listing.rankedAuction?.auctionEnded &&
    !nft.listing.liveBid;
  const canShowOwnerMode =
    (!hasAuction || endedAuctionWithoutLiveBid) &&
    !nft.listing.isBidAccepted &&
    !isPendingSale;

  const canBeUnlocked =
    nft.unlockable?.canBeUnlocked &&
    session.isLoggedIn() &&
    isCurrentUserTokenOwner;

  const rawAssetUrl =
    !nft.contract.isExternal && nft.metadata.rawAwsUrl
      ? nft.metadata.rawAwsUrl
      : nft.metadata.rawMediaUrl;

  return (
    <>
      <ProductTitle title={generateTitleText(nft)} />
      <ProductAuthorDetails
        author={nft.metadata.author}
        collaborators={nft.metadata.collaborators}
        collection={nft.metadata.nftCollection}
        isGenerative={nft.contract.isGenerative}
      />
      <ProductLabels
        nft={nft}
        showDropsTimer={showDropsTimer}
        showProductAuctionTimer={showProductAuctionTimer}
        showRankedAuctionTimer={showRankedAuctionTimer}
        onProductTimerEnd={onProductTimerEnd}
      >
        {!!nft.metadata.dropMetadata?.additionalDetails && !!isCreatorOfNFT && (
          <div
            className={joinClasses(
              MPFonts.paragraphSmall,
              styles.productMessage
            )}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                nft.metadata.dropMetadata.additionalDetails
              ),
            }}
          />
        )}
        {!!nft.unlockable?.unlocked && (
          <div
            className={joinClasses(
              styles.unlockableMessageContainer,
              MPFonts.paragraphNormal
            )}
          >
            The Unlockable item has been claimed
          </div>
        )}
        <ProductActions
          nft={nft}
          showDropsTimer={showDropsTimer}
          showProductAuctionTimer={showProductAuctionTimer}
          showRankedAuctionTimer={showRankedAuctionTimer}
          invalidate={invalidate}
          openSeaUrl={nft.openseaUrl}
        />
        {!!canShowOwnerMode && (
          <DefaultSuspense>
            <ProductOwnerMode
              nft={nft}
              invalidate={invalidate}
              approvalRegistryContract={approvalRegistryContract}
            />
          </DefaultSuspense>
        )}

        {canBeUnlocked ? (
          <ClaimUnlockable
            nftId={nftId}
            stripeQuery={{ variables: {} }}
            unlockable={nft.unlockable}
            invalidate={invalidate}
          />
        ) : null}
      </ProductLabels>
      <ProductSocialBar nft={nft} />
      {nftHasRankedAuction(nft) && (
        <RankedAuction nft={nft} onAuctionUpdate={onAuctionUpdate} />
      )}
      <ProductExhibition
        exhibition={
          nft.metadata.dropMetadata?.drop ||
          (nft.contract.isGenerative ? nft.metadata.nftCollection?.drop : null)
        }
      />
      <ProductAboutArtists
        author={nft.metadata.author}
        collaborators={nft.metadata.collaborators}
      />
      <MPDivider />
      <ProductDescriptions
        artwork={{
          description: nft.metadata.description,
          videoPreviewImageUrl: nft.metadata.descriptionImageUrl,
          videoUrl: nft.metadata.descriptionVideoUrl,
        }}
        collectorReward={{
          description: nft.metadata.collectorReward?.description,
        }}
        unlockable={{
          description: nft.unlockable?.description,
        }}
      />
      <ProductProvenance
        nft={nft}
        queryVariables={{
          first: 1,
          isLiveOnChain: false,
          productSlug: nft.listing.productSlug,
        }}
      />
      <ProductDetailsMetadata
        canDownload={!!isCurrentUserTokenOwner && !nft.contract.isExternal}
        canRefresh={nft.contract.isExternal}
        height={nft.metadata.mediaMetadata.height}
        metadataUrl={nft.manifoldTokenUri ?? nft.metadata.metadataUrl}
        nftId={nftId}
        nftTitle={generateTitleText(nft)}
        rawMediaUrl={rawAssetUrl}
        rawfileExtension={nft.metadata.rawfileExtension}
        etherscanUrl={nft.ethTx?.transactionUrl}
        width={nft.metadata.mediaMetadata.width}
        contractType={nft.contract.contractType as EthereumContractTypeEnum}
        onRefresh={invalidate}
      />
      <ProductTags
        queryVariables={{
          first: 1,
          isLiveOnChain: false,
          productSlug: nft.listing.productSlug,
        }}
      />
      <ProductAttributes
        queryVariables={{
          pk: parseInt(nft.metadata.pk, 10),
        }}
      />
      <MPDivider />
    </>
  );
}
