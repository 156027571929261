/**
 * @generated SignedSource<<dd0e0de548ad03fe6f580ebb4f1f1638>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PurchaseWithCustodialWalletArguments = {
  buyerAddress: `0x${string}`;
  depositMetadataId: number;
  nonce: number;
  productId: number;
  transactionId: `0x${string}`;
  isPresale?: boolean | null;
  discountCode?: string | null;
  emailAddress?: string | null;
  sessionId?: string | null;
};
export type ListingPurchaseWithCustodialWalletMutation$variables = {
  requestData: PurchaseWithCustodialWalletArguments;
};
export type ListingPurchaseWithCustodialWalletMutation$data = {
  readonly purchaseWithCustodialWallet: {
    readonly success: boolean | null;
  } | null;
};
export type ListingPurchaseWithCustodialWalletMutation = {
  variables: ListingPurchaseWithCustodialWalletMutation$variables;
  response: ListingPurchaseWithCustodialWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "PurchaseWithCustodialWalletMutation",
    "kind": "LinkedField",
    "name": "purchaseWithCustodialWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingPurchaseWithCustodialWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingPurchaseWithCustodialWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1df7a01183b383e6df125ab7f232993",
    "id": null,
    "metadata": {},
    "name": "ListingPurchaseWithCustodialWalletMutation",
    "operationKind": "mutation",
    "text": "mutation ListingPurchaseWithCustodialWalletMutation(\n  $requestData: PurchaseWithCustodialWalletArguments!\n) {\n  purchaseWithCustodialWallet(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c49a3a37a481af236cdba700eab0ca8";

export default node;
