import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { merge } from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  ARTISTS_FILTERS_DEFAULT_STATE,
  ArtistsFiltersState,
} from './artists/Filters';
import {
  ARTWORKS_FILTERS_DEFAULT_STATE,
  ArtworksFiltersState,
} from './artworks/Filters';
import {
  EDITORIALS_FILTERS_DEFAULT_STATE,
  EditorialsFiltersState,
} from './editorials/Filters';
import {
  EXHIBITIONS_FILTERS_DEFAULT_STATE,
  ExhibitionsFiltersState,
} from './exhibitions/Filters';
import ExploreDialog from './dialog';
import { ExploreType, FiltersStateType } from './types';

const getFiltersDefaultState = (type: ExploreType) => {
  switch (type) {
    case ExploreType.Artworks:
      return ARTWORKS_FILTERS_DEFAULT_STATE;
    case ExploreType.Editorial:
      return EDITORIALS_FILTERS_DEFAULT_STATE;
    case ExploreType.Exhibitions:
      return EXHIBITIONS_FILTERS_DEFAULT_STATE;
    case ExploreType.Artists:
      return ARTISTS_FILTERS_DEFAULT_STATE;
    default:
      return null;
  }
};

type OpenFiltersInitialType = (
  | {
      type: ExploreType.Artists;
      filters?: Partial<ArtistsFiltersState>;
    }
  | {
      type: ExploreType.Artworks;
      filters?: Partial<ArtworksFiltersState>;
    }
  | {
      type: ExploreType.Editorial;
      filters?: Partial<EditorialsFiltersState>;
    }
  | {
      type: ExploreType.Exhibitions;
      filters?: Partial<ExhibitionsFiltersState>;
    }
) & {
  hideHero?: boolean;
};

type ExploreContextType = {
  close: () => void;
  open: (initial?: OpenFiltersInitialType) => void;
  show: boolean;
};

const ExploreContext = createContext<ExploreContextType>(null);

export const useExplore = () => {
  const context = useContext(ExploreContext);
  if (!context) {
    throw new Error('useExplore must be used within a ExploreProvider');
  }
  return context;
};

export function ExploreProvider({ children }) {
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [hideHero, setHideHero] = useState<boolean>(false);
  const [exploreType, setExploreType] = useState<ExploreType>(null);
  const filtersState = useState<FiltersStateType>(null);

  useEffect(() => setShow(false), [location]);

  const filters = useMemo(
    () => merge({}, getFiltersDefaultState(exploreType), filtersState[0]),
    [exploreType, filtersState]
  );

  const handleFiltersChange = useCallback(
    (value: Partial<FiltersStateType>) =>
      filtersState[1]((prev: FiltersStateType) => ({
        ...(prev as FiltersStateType),
        ...(value as FiltersStateType),
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFiltersReset = useCallback(
    (
      initialType?: ExploreType,
      initialFilters: Partial<FiltersStateType> = {} as FiltersStateType
    ) =>
      filtersState[1](
        merge(
          {},
          getFiltersDefaultState(
            initialType ?? exploreType
          ) as FiltersStateType,
          initialFilters
        )
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exploreType]
  );

  const handleTypeChange = useCallback(
    (value: ExploreType) => {
      setExploreType(value);
      handleFiltersReset(value);
    },
    [handleFiltersReset]
  );

  const handleOpen = useCallback(
    (
      initial: OpenFiltersInitialType = {
        type: ExploreType.Artworks,
      }
    ) => {
      const initialType = initial?.type ?? ExploreType.Artworks;
      setExploreType(initialType);
      handleFiltersReset(initialType, initial?.filters);
      setHideHero(initial?.hideHero || false);
      setShow(true);
    },
    [handleFiltersReset]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const value = useMemo(
    (): ExploreContextType => ({
      close: handleClose,
      open: handleOpen,
      show,
    }),
    [show, handleOpen, handleClose]
  );

  return (
    <ExploreContext.Provider value={value}>
      {children}

      {!!show && (
        <ExploreDialog
          exploreType={exploreType}
          filters={filters}
          hideHero={hideHero}
          onClose={handleClose}
          onFiltersChange={handleFiltersChange}
          onFiltersReset={handleFiltersReset}
          onTypeChange={handleTypeChange}
        />
      )}
    </ExploreContext.Provider>
  );
}
