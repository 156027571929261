import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import {
  MPAnimations,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  DiscordIcon,
  InstagramIcon,
  TwitterIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { GenreEnum } from 'types/__generated__/graphql';

import Panel from 'components/panels/Panel';
import {
  DISCORD_MP_PROFILE_URL,
  INSTAGRAM_MP_PROFILE_URL,
  LEVER_CAREERS_URL,
  TWITTER_MP_PROFILE_URL,
} from 'constants/ExternalUrls';
import ROUTES from 'constants/Routes';
import { ExploreType } from 'pages/explore/types';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import CSSGlobal from 'types/enums/css/Global';
import useNavbarGTM from 'utils/GTM/navbar';

import LinkSection, { ExploreNavLink, NavLink } from './LinkSection';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';

const socialIconClassName = joinClasses(
  MPAnimations.Color.DarkToLight,
  MPColorClass.SolidNeutralGray5
);

function PanelFooter() {
  const Track = useNavbarGTM();

  return (
    <div className={panelStyles.panelFooter}>
      <div className={joinClasses(CSSGlobal.Flex.Row, panelStyles.socialIcons)}>
        <a
          href={TWITTER_MP_PROFILE_URL}
          onClick={() => Track.clickNavigationItem(TWITTER_MP_PROFILE_URL)}
        >
          <TwitterIcon className={socialIconClassName} fontSize="medium" />
        </a>
        <a
          href={INSTAGRAM_MP_PROFILE_URL}
          className={MPAnimations.Color.DarkToLight}
          onClick={() => Track.clickNavigationItem(INSTAGRAM_MP_PROFILE_URL)}
        >
          <InstagramIcon className={socialIconClassName} fontSize="medium" />
        </a>
        <a
          href={DISCORD_MP_PROFILE_URL}
          className={MPAnimations.Color.DarkToLight}
          onClick={() => Track.clickNavigationItem(DISCORD_MP_PROFILE_URL)}
        >
          <DiscordIcon className={socialIconClassName} fontSize="medium" />
        </a>
      </div>
      <div className={joinClasses(CSSGlobal.Flex.Row, panelStyles.footerLinks)}>
        <Link
          className={joinClasses(
            'anchor',
            MPAnimations.Color.DarkToLight,
            MPColorClass.SolidNeutralGray4,
            MPFonts.paragraphXSmall
          )}
          to={ROUTES.PRIVACY()}
          reloadDocument
          onClick={() => Track.clickNavigationItem(ROUTES.PRIVACY())}
        >
          Privacy Policy
        </Link>
        <Link
          className={joinClasses(
            'anchor',
            MPAnimations.Color.DarkToLight,
            MPColorClass.SolidNeutralGray4,
            MPFonts.paragraphXSmall
          )}
          to={ROUTES.TERMS()}
          onClick={() => Track.clickNavigationItem(ROUTES.TERMS())}
          reloadDocument
        >
          Terms of Use
        </Link>
        <a
          className={joinClasses(
            'anchor',
            MPAnimations.Color.DarkToLight,
            MPColorClass.SolidNeutralGray4,
            MPFonts.paragraphXSmall
          )}
          onClick={() => Track.clickNavigationItem(LEVER_CAREERS_URL)}
          href={LEVER_CAREERS_URL}
        >
          Careers
        </a>
      </div>
    </div>
  );
}

export default function SitePanel({ ...props }) {
  const modalProps = useMemo(
    () => ({
      container: window.parent.document.body,
    }),
    []
  );

  const showExplore3 = useGateValue('gate_explore_v3');
  const reloadDocument = useContext(IsDjangoContext);
  const isMobile = useIsMobile();

  return (
    <Panel
      isNav
      anchor="left"
      trackingTitle="Site"
      {...props}
      ModalProps={modalProps}
    >
      <div className={panelStyles.bodyWithFooter}>
        <div className={panelStyles.panelBody}>
          {showExplore3 ? (
            <>
              <LinkSection
                title="Explore"
                className={panelStyles.firstNonEmptyElement}
              >
                <ExploreNavLink
                  exploreType={ExploreType.Artworks}
                  to={ROUTES.EXPLORE.ARTWORKS()}
                  reloadDocument={reloadDocument}
                >
                  Artworks
                </ExploreNavLink>
                <ExploreNavLink
                  exploreType={ExploreType.Exhibitions}
                  to={ROUTES.EXHIBITIONS()}
                  reloadDocument
                >
                  Exhibitions
                </ExploreNavLink>
                <ExploreNavLink
                  exploreType={ExploreType.Artists}
                  to={ROUTES.EDITORIAL()}
                  reloadDocument
                >
                  Artists
                </ExploreNavLink>
                <ExploreNavLink
                  exploreType={ExploreType.Editorial}
                  to={ROUTES.EDITORIAL()}
                  reloadDocument
                >
                  Editorial
                </ExploreNavLink>
              </LinkSection>
              <LinkSection
                title="Transactions"
                className={panelStyles.firstNonEmptyElement}
              >
                <NavLink to={ROUTES.ACTIVITY()} reloadDocument={reloadDocument}>
                  Activity
                </NavLink>
              </LinkSection>
            </>
          ) : (
            <>
              <LinkSection
                title="Explore"
                className={panelStyles.firstNonEmptyElement}
              >
                <NavLink
                  to={ROUTES.EXPLORE.ARTWORKS()}
                  reloadDocument={reloadDocument}
                >
                  Artworks
                </NavLink>
                <NavLink to={ROUTES.ACTIVITY()} reloadDocument={reloadDocument}>
                  Activity
                </NavLink>
                <NavLink to={ROUTES.EXHIBITIONS()} reloadDocument>
                  Exhibitions
                </NavLink>
                <NavLink to={ROUTES.EDITORIAL()} reloadDocument>
                  Editorial
                </NavLink>
              </LinkSection>
              <LinkSection title="Genres">
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.GenerativeArt)}
                  reloadDocument={reloadDocument}
                >
                  Generative
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.Illustration)}
                  reloadDocument={reloadDocument}
                >
                  Illustration
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.Photography)}
                  reloadDocument={reloadDocument}
                >
                  Photography
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.X3DArt)}
                  reloadDocument={reloadDocument}
                >
                  3D&nbsp;Art
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.Animation)}
                  reloadDocument={reloadDocument}
                >
                  Animation
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.AiArt)}
                  reloadDocument={reloadDocument}
                >
                  AI
                </NavLink>
                <NavLink
                  to={ROUTES.EXPLORE.GENRE(GenreEnum.Phygitals)}
                  reloadDocument={reloadDocument}
                >
                  Unlockables
                </NavLink>
              </LinkSection>
            </>
          )}
          <LinkSection title="Help Center">
            <NavLink to={ROUTES.FAQ.SUPPORT()} reloadDocument>
              Support
            </NavLink>
            <NavLink to={ROUTES.FAQ()} reloadDocument>
              FAQ
            </NavLink>
          </LinkSection>
          <LinkSection title="Company">
            <NavLink to={ROUTES.ART_MONITORING_SERVICE()} reloadDocument>
              Art&nbsp;Monitoring&nbsp;Service
            </NavLink>
            {!showExplore3 && (
              <NavLink to={ROUTES.LAUNCHPAD()} reloadDocument>
                Launchpad
              </NavLink>
            )}
            <NavLink to={ROUTES.ABOUT()} reloadDocument>
              About&nbsp;Us
            </NavLink>
            <NavLink to={ROUTES.CONCIERGE()} reloadDocument>
              Concierge&nbsp;Program
            </NavLink>
          </LinkSection>
          {!!isMobile && <PanelFooter />}
        </div>
        {!isMobile && <PanelFooter />}
      </div>
    </Panel>
  );
}
