import { useCallback, useRef } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import { ExploreType } from '../types';

import * as styles from 'css/pages/explore/dialog/Tabs.module.css';

interface TabProps {
  exploreType: ExploreType;
  isSelected: boolean;
  onSelect: (exploreType: ExploreType) => void;
}

function Tab({ exploreType, isSelected, onSelect }: TabProps) {
  const handleClick = useCallback(
    () => onSelect(exploreType),
    [exploreType, onSelect]
  );

  return (
    <button
      className={joinClasses(MPFonts.textSmallSemiBold, styles.tab, {
        [styles.selected]: isSelected,
      })}
      type="button"
      onClick={handleClick}
    >
      {ExploreType[exploreType]}
    </button>
  );
}

export interface ExploreDialogTabsProps {
  exploreType: ExploreType;
  onTypeChange: (exploreType: ExploreType) => void;
}

export default function ExploreDialogTabs({
  exploreType,
  onTypeChange,
}: ExploreDialogTabsProps) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleChipClick = useCallback((event) => {
    if (!scrollRef.current) return;

    const container = scrollRef.current;
    const { left: containerLeft } = container.getBoundingClientRect();
    const clickXPosition = event.clientX - containerLeft;
    const { scrollWidth, clientWidth, scrollLeft } = container;
    const nearEndThreshold = 100;

    const isNearEnd = clickXPosition > clientWidth - nearEndThreshold;
    const isNearStart = clickXPosition < nearEndThreshold;

    if (isNearEnd) {
      const newScrollPosition = Math.min(
        scrollLeft + (clientWidth - nearEndThreshold),
        scrollWidth - clientWidth
      );
      container.scrollTo({
        behavior: 'smooth',
        left: newScrollPosition,
      });
    } else if (isNearStart) {
      const newScrollPosition = Math.max(
        scrollLeft - (clientWidth - nearEndThreshold),
        0
      );
      container.scrollTo({
        behavior: 'smooth',
        left: newScrollPosition,
      });
    }
  }, []);

  return (
    <div className={joinClasses(styles.container)}>
      <div
        className={joinClasses(CSSGlobal.NoScrollbar, styles.innerContainer)}
        ref={scrollRef}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.CenteredRow,
            CSSGlobal.Flex.NoWrap,
            CSSGap[16],
            styles.title
          )}
          onClick={handleChipClick}
          role="button"
          tabIndex={0}
          onKeyDown={handleChipClick}
        >
          {[
            ExploreType.Artworks,
            ExploreType.Exhibitions,
            ExploreType.Artists,
            ExploreType.Editorial,
          ].map((key) => (
            <Tab
              key={key}
              exploreType={ExploreType[key]}
              isSelected={exploreType === ExploreType[key]}
              onSelect={onTypeChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
