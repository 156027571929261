.container {
  --explore-gap: 32px;

  box-sizing: border-box;
  gap: var(--explore-gap);
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: var(--explore-gap) var(--explore-gap) 0 var(--explore-gap);

  @media screen and (min-width: 1920px) {
    max-width: 1760px;
  }

  @media (--mq-is-mobile) {
    --explore-gap: 24px;
    padding: 0;
  }
}
