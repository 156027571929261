import { useCallback, useState } from 'react';
import { InputAdornment } from '@mui/material';

import {
  MPActionButton,
  MPCheckbox,
  MPColorClass,
  MPDivider,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { ArrowDownIcon, ArrowUpIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import GTM from 'GTM';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import {
  ARTWORKS_FILTERS_DEFAULT_STATE,
  ArtworksFiltersState,
} from './Filters';

import * as styles from 'css/pages/explore/artworks/MoreFilters.module.css';

interface MoreFiltersDialogProps {
  onChange: (state: Partial<ArtworksFiltersState>) => void;
  onClose: () => void;
  state: Partial<ArtworksFiltersState>;
}

const INTEGER_REGEX = /^[1-9][0-9]*|0$/;

export function MoreFiltersDialog({
  state,
  onChange,
  onClose,
}: MoreFiltersDialogProps) {
  const [localState, setLocalState] =
    useState<Partial<ArtworksFiltersState>>(state);

  const handleApply = useCallback(() => {
    onChange(localState);
    onClose();
  }, [localState, onChange, onClose]);

  const handleClear = useCallback(
    () => setLocalState(ARTWORKS_FILTERS_DEFAULT_STATE),
    []
  );

  const sectionClassName = joinClasses(CSSGlobal.Flex.Col, CSSGap[10]);
  const sectionHeaderClassName = joinClasses(
    MPFonts.textSmallMedium,
    MPColorClass.SolidNeutralGray4
  );
  const sectionColumnsClassName = joinClasses(CSSGap[16], styles.columns);

  return (
    <MPStandardDialog
      title="More Filters"
      open
      onClose={onClose}
      actionButton={
        <>
          <MPActionButton variant="secondary" onClick={handleClear}>
            Clear All
          </MPActionButton>
          <MPActionButton onClick={handleApply}>Apply</MPActionButton>
        </>
      }
    >
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
        <div className={sectionClassName}>
          <span className={sectionHeaderClassName}>Status</span>
          <div className={sectionColumnsClassName}>
            <MPCheckbox
              isChecked={!!localState.status[StatusEnum.InAuction]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  status: {
                    ...prevLocalState.status,
                    [StatusEnum.InAuction]: event?.target?.checked,
                  },
                }))
              }
              name="auctions"
              label="In Auction"
            />
            <MPCheckbox
              isChecked={!!localState.status[StatusEnum.HasOffers]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  status: {
                    ...prevLocalState.status,
                    [StatusEnum.HasOffers]: event?.target?.checked,
                  },
                }))
              }
              name="has-offers"
              label="Has Offers"
            />
            <MPCheckbox
              isChecked={!!localState.status[StatusEnum.BuyNow]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  status: {
                    ...prevLocalState.status,
                    [StatusEnum.BuyNow]: event?.target?.checked,
                  },
                }))
              }
              name="buy-now"
              label="Listed For Sale"
            />
          </div>
        </div>
        <MPDivider />

        <div className={sectionClassName}>
          <span className={sectionHeaderClassName}>Editions</span>
          <div className={sectionColumnsClassName}>
            <MPCheckbox
              isChecked={!!localState.editions[EditionTypeEnum.Single]}
              disabled={!!localState.editions[EditionTypeEnum.Limited]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  editions: {
                    ...prevLocalState.editions,
                    [EditionTypeEnum.Single]: event?.target?.checked,
                  },
                }))
              }
              name="single-edition"
              label="Uniques"
            />
            <MPCheckbox
              isChecked={!!localState.editions[EditionTypeEnum.Limited]}
              disabled={!!localState.editions[EditionTypeEnum.Single]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  editions: {
                    ...prevLocalState.editions,
                    [EditionTypeEnum.Limited]: event?.target?.checked,
                  },
                }))
              }
              name="multiple-edition"
              label="Limited Editions"
            />
          </div>
        </div>
        <MPDivider />

        <div className={sectionClassName}>
          <span className={sectionHeaderClassName}>Media Type</span>
          <div className={sectionColumnsClassName}>
            <MPCheckbox
              isChecked={!!localState.mediaTypes[MediaTypeEnum.Still]}
              disabled={!!localState.mediaTypes[MediaTypeEnum.Motion]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  mediaTypes: {
                    ...prevLocalState.mediaTypes,
                    [MediaTypeEnum.Still]: event?.target?.checked,
                  },
                }))
              }
              name="still"
              label="Still"
            />
            <MPCheckbox
              isChecked={!!localState.mediaTypes[MediaTypeEnum.Motion]}
              disabled={!!localState.mediaTypes[MediaTypeEnum.Still]}
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  mediaTypes: {
                    ...prevLocalState.mediaTypes,
                    [MediaTypeEnum.Motion]: event?.target?.checked,
                  },
                }))
              }
              name="motion"
              label="Motion"
            />
          </div>
        </div>
        <MPDivider />

        <div className={sectionClassName}>
          <span className={sectionHeaderClassName}>Dimensions</span>
          <div className={sectionColumnsClassName}>
            <MPCheckbox
              isChecked={!!localState.aspectRatios[AspectRatioEnum.Landscape]}
              disabled={
                !!localState.aspectRatios[AspectRatioEnum.Portrait] &&
                !!localState.aspectRatios[AspectRatioEnum.Square]
              }
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  aspectRatios: {
                    ...prevLocalState.aspectRatios,
                    [AspectRatioEnum.Landscape]: event?.target?.checked,
                  },
                }))
              }
              name="landscape"
              label="Landscape"
            />
            <MPCheckbox
              isChecked={!!localState.aspectRatios[AspectRatioEnum.Portrait]}
              disabled={
                !!localState.aspectRatios[AspectRatioEnum.Landscape] &&
                !!localState.aspectRatios[AspectRatioEnum.Square]
              }
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  aspectRatios: {
                    ...prevLocalState.aspectRatios,
                    [AspectRatioEnum.Portrait]: event?.target?.checked,
                  },
                }))
              }
              name="portrait"
              label="Portrait"
            />
            <MPCheckbox
              isChecked={!!localState.aspectRatios[AspectRatioEnum.Square]}
              disabled={
                !!localState.aspectRatios[AspectRatioEnum.Landscape] &&
                !!localState.aspectRatios[AspectRatioEnum.Portrait]
              }
              onChange={(event) =>
                setLocalState((prevLocalState) => ({
                  ...prevLocalState,
                  aspectRatios: {
                    ...prevLocalState.aspectRatios,
                    [AspectRatioEnum.Square]: event?.target?.checked,
                  },
                }))
              }
              name="square"
              label="Square"
            />
          </div>
        </div>
        <MPDivider />

        <div className={sectionClassName}>
          <div className={sectionColumnsClassName}>
            <MPStyledTextField
              placeholder="Min Width"
              value={localState.size.minWidth ?? ''}
              setValue={(val) => {
                const minWidth = val as string;
                if (INTEGER_REGEX.test(minWidth) || minWidth === '')
                  setLocalState((prevLocalState) => ({
                    ...prevLocalState,
                    size: { ...prevLocalState.size, minWidth },
                  }));
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span className={MPColorClass.SolidNeutralGray4}>px</span>
                </InputAdornment>
              }
            />
            <MPStyledTextField
              placeholder="Min Height"
              value={localState.size.minHeight ?? ''}
              setValue={(val) => {
                const minHeight = val as string;
                if (INTEGER_REGEX.test(minHeight) || minHeight === '')
                  setLocalState((prevLocalState) => ({
                    ...prevLocalState,
                    size: { ...prevLocalState.size, minHeight },
                  }));
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span className={MPColorClass.SolidNeutralGray4}>px</span>
                </InputAdornment>
              }
            />
          </div>
        </div>
      </div>
    </MPStandardDialog>
  );
}

export default function MoreFilters(
  props: Omit<MoreFiltersDialogProps, 'onClose'>
) {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = useCallback(() => {
    setOpen(true);
    GTM.explore.trackOpenFilterBox();
  }, []);

  const handleCloseDialog = useCallback(() => setOpen(false), []);

  return (
    <>
      <MPActionButton
        type="button"
        variant={open ? 'tertiary-inverted' : 'tertiary'}
        onClick={handleOpenDialog}
        endIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        className={CSSGlobal.Width.maxContent}
      >
        More Filters
      </MPActionButton>

      {!!open && (
        <MoreFiltersDialog
          state={props.state}
          onChange={props.onChange}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
